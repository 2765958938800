import React from 'react'
import Modal from 'react-modal';
import axios from 'axios';
import { getToken, config } from '../lib/config';


function Error(props) {

    function handleSubmit(e){
        window.location.reload();
    }

    function close(){
        //props.setMessage(null);
        window.location.reload();
    }

    return (
        <>
        <div className='errorBackground'></div>
        <div className='errorBody'>
            <table>
                <tr>
                    <td colSpan={3}>                
                        <img className='important' src='assets/images/important.svg'></img>
                    </td>
                </tr>
                
                <tr>
                    <td colSpan={3}>                
                        <div className='errorTitle errorTextCenter'>Error</div>
                    </td>
                </tr>
                <tr>
                    <td colSpan={3}>                
                        <div className='errorText errorTextCenter'>{props.message}</div>
                    </td>
                </tr>
                <tr>
                    <td colSpan={3}>                
                        <button className='stakeButton' onClick={close}>Close</button>
                    </td>
                </tr>
            </table>
            <img className="closeIcon" onClick={close} src='assets/images/close.png'></img>
        </div>
        </>
    )
}

export default Error