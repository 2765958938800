const error_map = {
    "Tx not authorized:" : "Burn transaction was not whitelisted!",
    "Error: Transaction declined" : "User declined transaction.",
}


export function mapError(e){
    let s = e.toString();
    let m = Object.keys(error_map).find((k)=>s.includes(k))
    if (m){
        return error_map[m];
    }
    else return s;
}