import { useContext } from 'react';
import VERSION from '../VERSION';
import {config} from './config-fe.js';
import { log } from './log.js';
import { ConnContext } from '../Components/ConnWrapper.js';

let version = '';

fetch(VERSION)
    .then(r => r.text())
    .then(text => {
        version = text;
    });

export function getConfig(active_lcd) {
    return config;
}

export function getChainId(active_lcd) {
    return Object.keys(config.lcd[active_lcd])[0];
}

export function getTokenAddress(active_lcd, denom) {
    return config.enabled_tokens.find((t) => t.name == denom).address;
}

export function getWhitelistTokenAddress(denom, chain_id) {
    return config.enabled_tokens.find((t) => t.name == denom && t.chain_id == chain_id).whitelisting_address;
}

export function getToken(active_lcd, denom) {
    return config.enabled_tokens.find((t) => t.name == denom)

}

export function getContractAddress(active_lcd) {
    return config.contract_addr[active_lcd];

}

export function getEnabledTokens() {
    return JSON.parse(JSON.stringify(config.enabled_tokens));
}

export function getEnabledTokensByChain(chain_id) {
    return config.enabled_tokens.filter((t) => t.chain_id == chain_id);
}

export function getEnabledChains() {
    let chains = {};
    for (const t of config.enabled_tokens){
        
        chains[t.chain_id] = { name : t.chain_name, icon: t.chain_icon, chain_id : t.chain_id };
    }
    return chains;
}

export function getDefaultChain() {
    let chain;
    for (const t of config.enabled_tokens){
        if (t.default_chain == 1) chain = { token_name: t.name, name : t.chain_name, icon: t.chain_icon, chain_id : t.chain_id };
    }
    return chain;
}

export function getDefaultCoinForChain(chain_id) {
    for (const t of config.enabled_tokens){
        if (t.default_token == 1 && t.chain_id == chain_id) return t;
    }
}

export function useWallet(){
    const {account, chain} = useContext(ConnContext);
}


// export function parseWallet(w) {
//     let wallet = {};
//     if (!w) return wallet;
//     let chain_id;
//     for (const k of Object.keys(w)){
//         if (w[k]) chain_id = k;
//     }
//     if (!chain_id) return wallet;
//     if (!w[chain_id].bech32Address) return wallet;
//     wallet.address = w[chain_id].bech32Address;
//     wallet.chainId = chain_id
//     wallet.network = config.active_lcd;
//     return wallet;
// }

export function getVersion() {
    return version;
}