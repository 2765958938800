import React, { useCallback, useState, useEffect } from 'react';
import { Buffer } from 'buffer'
import { getChainId, getContractAddress, parseWallet, getEnabledTokens, getEnabledChains } from '../lib/config.js';

import axios from 'axios';

import { useExecuteContract, useSendIbcTokens, useStargateSigningClient } from 'graz';
import { GasPrice } from '@cosmjs/stargate';
import { mapError } from '../lib/errors';
import { log } from '../lib/log';
import { useWallet } from './hooks';

function IbcTx(props) {
  const wallet = useWallet();

  const workflowParams = props.workflowParams;

  log("workflowParams", "info");
  log(workflowParams, "info");

  const [txResult, setTxResult] = useState(null);

  const { data: signingClient } = useStargateSigningClient(
    {
      chainId: "provider",
      multiChain: false,
      opts: {
        gasPrice: GasPrice.fromString("0.025uatom"),
      }
    }
  );



  const { sendIbcTokens } = useSendIbcTokens({
    onError: (error, data) => {
      if (data.signingClient) {
        log(data, "info");
        log(error, "info");
        props.setModalMessage([]);
        setTxResult({ hash: "", error: mapError(error) });
        props.setErrorMessage(`Wallet response: ${mapError(error)}.`);
      } else {
        log("Waiting for signing client", "info");
      }
    },
    onSuccess: (result) => {
      log(result, "info");
      log("Broadcast result", { hash: result.transactionHash }, "info");
      setTxResult({ hash: result.transactionHash });
      props.setModalMessage(["fire"]);
      updateTx(wallet.network, { hash: result.transactionHash });
    },
    onLoading: (a) => { log("IBCRESl"); log(a) },
  }
  );

  function parseSigningClientErrors(e) {
    let s = e.toString();
    if (s.includes("Tx not authorized:")) {
      return "Burn transaction was not whitelisted!";
    } else if (s.includes("Error: Transaction declined")) {
      return "User declined transaction.";
    }
    else return s;
  }


  let chainID = props.chainFrom;

  function updateTx(network, txResult) {
    axios.get(`/api/${chainID}/${network}/tx2?txhash=${txResult.hash}`).then((r) => {
      log(r);
      if (r.data.code == -1) {
        setTimeout(function () { updateTx(network, txResult) }, 1000);
      } else if (r.data.code == 0) {
        props.setShowSuccess(true);
        props.setTxhash(txResult.hash);
        window.startAnimation();
      } else {
        props.setErrorMessage("Oops! We have a problem. Developers have been notified.");
      }
    });
  }



  useEffect(() => {
    // if (!connectedWallet) {
    //   return;
    // }

    if (txResult) {
      return;
    }
    log("send IBC", "info");
    log(signingClient, "info");

    if (!signingClient) {
      return;
    }


    let msg_workflow_params = [];
    let total_burn_amount = 0;
    let burn_denom;
    for (const w of workflowParams) {
      msg_workflow_params.push({
        id: w.workflow_id,
        burn_amount: "" + w.burn_amount,
        base_amount: "" + w.base_amount,
        denom: w.denom
      })
      total_burn_amount += w.burn_amount;
      burn_denom = w.denom;
    }

    let t = getEnabledTokens().find((t) => t.whitelisting_address == burn_denom);
    let funds = { amount: "" + total_burn_amount, denom: t.native_denom };

    let memo = {
      wasm: {
        contract: getContractAddress(wallet.network),
        msg: {
          burn: {
            workflow_params: msg_workflow_params
          }
        }
      }
    };

    try {

      let result = sendIbcTokens({
        signingClient: signingClient,
        senderAddress: wallet.address,
        recipientAddress: getContractAddress(wallet.network),
        transferAmount: funds,
        sourcePort: "transfer",
        sourceChannel: "channel-32",
        timeoutHeight: {
          revisionHeight: 33843602,
          revisionNumber: 1
        },
        timeoutTimestamp: 2736163341997943817,
        fee: "auto",
        memo: `${JSON.stringify(memo)}`
      })
      log(result, "info");
    } catch (error) {
      log(error, "info");
      props.setModalMessage([]);
      props.setErrorMessage("Oops! We have a problem. Developers have been notified. (1001)");
    }

  }, [signingClient]);

  return (
    <>
    </>
  );
}
export default IbcTx;
