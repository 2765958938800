import React, { createContext, useContext, useEffect } from 'react'
import Burn from './Burn.js';
import Ibc from './Ibc.js';
import Burnlog from './Burnlog.js'
import Modal from 'react-modal';
import { getConfig, getDefaultChain, getEnabledChains, getVersion, parseWallet, setConfig as setGlobalConfig } from '../lib/config.js'
import axios from 'axios';

import { checkWallet, getWallet, useAccount, useConnect, useDisconnect, WALLET_TYPES } from "graz";
import { walletConfig } from '../lib/wallet-config.js';
import { log } from '../lib/log';
import { ConnContext } from './ConnWrapper.js';
import { useWallet } from './hooks.js';

function Main(props) {

  const {account, chainFrom, setChainFrom} = useContext(ConnContext);

  const wallet = useWallet();

  const { connect, status } = useConnect();

  const { disconnect } = useDisconnect();
  const [formData, setFormData] = React.useState({
    step: "burn",
  });

  const [chainModalIsOpen, setChainModalIsOpen] = React.useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);

  const { step } = formData;

  // useEffect(()=>{
  //   if (account){
  //     for (const k of Object.keys(account)){
  //       if (account[k]) setChainFrom(k);
  //     }
  //   }
  // });

  function setCurrentPage(s) {
    const { step } = formData
    setFormData({ step: s })
  }

  function openModal() {
    setIsOpen(true);
  }

  function openChainModal(){
    setChainModalIsOpen(true);
  }

  function changeChainFrom(chain_id){
    // let w = getWallet();
    // try {
    //   w.disable();
    // } catch(e){}
    // disconnect();
    //setTimeout(()=>{
      setChainFrom(chain_id)
    //}, 500); // :)
    closeChainModal();
    //openModal();
  }

  function tryConnect(params) {
    connect(params);
    //setWalletConfig(id);
    closeModal();
  }

  function tryDisconnect(p) {
    let w = getWallet();
    w.disable();
    disconnect();
  }

  function afterOpenModal() {
  }

  function onAfterOpenChain(){

  }
  function closeModal() {
    setIsOpen(false);
  }
  function closeChainModal(){
    setChainModalIsOpen(false);
  }

  const project = () => {
    switch (step) {
      case "burn": return <Burn account={account} chainFrom={chainFrom} broadcast={{}} isAgreement={props.isAgreement} handleAgreement={props.handleAgreement} setCurrentPage={setCurrentPage} connectModal={openModal} />;
      case "burnlog": return <Burnlog setCurrentPage={setCurrentPage} account={account} />;
      default: return <Burn />;
    }
  }

  // burning effect script
  const script = document.createElement("script");
  script.src = "/assets/burn.js";
  script.id = "burnScript";
  if (document.getElementById("burnScript") == null) document.body.appendChild(script);


  log(getEnabledChains()[chainFrom])



  return (
    <>
      <button className='chainButton' onClick={() => openChainModal()}><img className="coinIcon" src={getEnabledChains()[chainFrom].icon}></img></button>
      {wallet.address ? (
        <>
          <button className='connectedButton' onClick={
            (e) => tryDisconnect(e)
          }><img className="walletIcon" src='assets/images/aside.svg'></img><div>{wallet.address.slice(0, 7)}...{wallet.address.slice(wallet.address.length - 4, wallet.address.length)}</div></button></>
      ) : (
        <button className='connectedButton' onClick={() => openModal()} style={{ width: "144px", paddingLeft: "44px"}}>Connect</button>
      )}
      {/* 
      <div> IBC
      {isConnected && parseWallet(account).address ? 
        <Ibc account={account}></Ibc>: "NO IBC"
      }
      </div> */}

      <table className='mainTable'>
        <tr className='firstRow'>
          <td className='topCol'>
            <img className='logo' src='assets/images/logo.png'></img>
          </td>
          <td>
            <h2 className='logoText'>CosmoBurn</h2>
            <p className='logoSubtext'>Burn to Earn</p>
          </td>
          <td className='connectCol'>


          </td>
        </tr>
        <tr>
          <td className='mainCol' colSpan={3}>
            <main>
              {project()}
            </main>


          </td>
        </tr>

        <div className='social'>


          <div className='socialRow'>
            <div className='socialButton'>
              <a href="https://twitter.com/cosmoburn" target="_blank" rel="noopener noreferrer"><img className='twitterIcon' src='assets/images/twitter2.svg'></img></a>
            </div>
            {/* <a href="https://twitter.com/cosmoburn" target="_blank" rel="noopener noreferrer">@X</a> */}
          </div>

          <div className='socialRow'>
            <div className='socialButton'>
              <a href="https://telegram.com/cosmoburn" target="_blank" rel="noopener noreferrer"><img src='assets/images/icons8-telegram-app.svg'></img></a>
            </div>
            {/* <a href="https://telegram.com/cosmoburn" target="_blank" rel="noopener noreferrer">@telegram</a> */}
          </div>

          <div className='socialRow'>
            <div className='socialButton'>
              <a href="https://telegram.com/cosmoburn" target="_blank" rel="noopener noreferrer"><img src='assets/images/icons8-medium-2.svg'></img></a>
            </div>
            {/* <a href="https://telegram.com/cosmoburn" target="_blank" rel="noopener noreferrer">@telegram</a> */}
          </div>

        </div>

        <div className='version'>
          <p>{getVersion()}</p>
        </div>
      </table>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        className='connectModalBody'
        contentLabel=""
        appElement={document.getElementById('root')}
        id="modal"
        style={{
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.60)',
            'backdrop-filter': 'blur(5px)',
          }
        }}
      >
        <div className='modalTitle'>Connect Wallet</div>
        {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2> */}
        {WALLET_TYPES.map((name) => (
          <>
               {checkWallet(name) && walletConfig[name] ?
              <button className='connectedButton' onClick={() => tryConnect({ chainId: Object.values(getEnabledChains()).map((c)=>c.chain_id), walletType: name })} key={name+"neutron"}>
                <img style={{width:"18px", "margin-right":"15px", }} src={walletConfig[name].icon}></img>
                {walletConfig[name].label}  </button> : null}
          </>
        ))}

        <img className="closeIcon" onClick={closeModal} src='assets/images/close.png'></img>
      </Modal>

      <Modal
        isOpen={chainModalIsOpen}
        onAfterOpen={onAfterOpenChain}
        onRequestClose={closeChainModal}
        className='connectModalBody'
        contentLabel=""
        appElement={document.getElementById('root')}
        id="chain_modal"
        style={{
          overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.60)',
            'backdrop-filter': 'blur(5px)',
          }
        }}
      >
        <div className='modalTitle'>Choose a Chain to Burn From</div>
        {Object.keys(getEnabledChains()).map((chain)=>(
          <>
             <button className='connectedButton' onClick={() => changeChainFrom(getEnabledChains()[chain].chain_id)}><img className="coinIcon" style={{marginRight:"14px"}} src={getEnabledChains()[chain].icon}></img><div>{getEnabledChains()[chain].name}</div></button>
          </>
        ))}


        <img className="closeIcon" onClick={closeChainModal} src='assets/images/close.png'></img>
      </Modal>
    </>
  )
}

export default Main