import { defineChainInfo, defineChains } from 'graz' ;

export const cosmoshub = defineChainInfo({
  chainId: "cosmoshub-4",
  currencies: [
    {
      coinDenom: "atom",
      coinMinimalDenom: "uatom",
      coinDecimals: 6,
      coinGeckoId: "cosmos"
    },
    {
      coinDenom: "usdt",
      coinMinimalDenom: "ibc/F04D72CF9B5D9C849BB278B691CDFA2241813327430EC9CDC83F8F4CA4CDC2B0",
      coinDecimals: 6
    },
    {
      coinDenom: "ibc/4925E6ABA571A44D2BE0286D2D29AF42A294D0FF2BB16490149A1B26EAD33729",
      coinMinimalDenom: "ibc/4925E6ABA571A44D2BE0286D2D29AF42A294D0FF2BB16490149A1B26EAD33729",
      coinDecimals: 0
    },
    {
      coinDenom: "crowdp",
      coinMinimalDenom: "ibc/74C4FE1EC3BDD66B02C691496371DDBB86DDE512C5BC072D76262C6C9B4B20D1",
      coinDecimals: 18
    }
  ],
  rest: "https://cosmoshub.lava.build:443",
  rpc: "https://cosmoshub.tendermintrpc.lava.build:443",
  bech32Config: {
    bech32PrefixAccAddr: "cosmos",
    bech32PrefixAccPub: "cosmospub",
    bech32PrefixValAddr: "cosmosvaloper",
    bech32PrefixValPub: "cosmosvaloperpub",
    bech32PrefixConsAddr: "cosmosvalcons",
    bech32PrefixConsPub: "cosmosvalconspub"
  },
  chainName: "cosmoshub",
  feeCurrencies: [
    {
      coinDenom: "atom",
      coinMinimalDenom: "uatom",
      coinDecimals: 6,
      coinGeckoId: "cosmos",
      gasPriceStep: {
        low: 0.01,
        average: 0.025,
        high: 0.03
      }
    }
  ],
  stakeCurrency: {
    coinDenom: "atom",
    coinMinimalDenom: "uatom",
    coinDecimals: 6,
    coinGeckoId: "cosmos"
  },
  bip44: {
    coinType: 118
  }
});
export const neutron = defineChainInfo({
  chainId: "neutron-1",
  currencies: [
    {
      coinDenom: "ntrn",
      coinMinimalDenom: "untrn",
      coinDecimals: 6,
      coinGeckoId: "neutron-3"
    },
    {
      coinDenom: "atom",
      coinMinimalDenom: "ibc/C4CFF46FD6DE35CA4CF4CE031E643C8FDC9BA4B99AE598E9B0ED98FE3A2319F9",
      coinDecimals: 6
    },
    {
      coinDenom: "axlusdc",
      coinMinimalDenom: "ibc/F082B65C88E4B6D5EF1DB243CDA1D331D002759E938A0F5CD3FFDC5D53B3E349",
      coinDecimals: 6
    },
    {
      coinDenom: "tia",
      coinMinimalDenom: "ibc/773B4D0A3CD667B2275D5A4A7A2F0909C0BA0F4059C0B9181E680DDF4965DCC7",
      coinDecimals: 6
    },
    {
      coinDenom: "ASTROPEPE",
      coinMinimalDenom: "factory/neutron14henrqx9y328fjrdvz6l6d92r0t7g5hk86q5nd/uastropepe",
      coinDecimals: 6
    },
    {
      coinDenom: "wstETH",
      coinMinimalDenom: "factory/neutron1ug740qrkquxzrk2hh29qrlx3sktkfml3je7juusc2te7xmvsscns0n2wry/wstETH",
      coinDecimals: 18
    },
    {
      coinDenom: "sat",
      coinMinimalDenom: "ibc/DDC3C60EE82BF544F1A0C6A983FF500EF1C14DE20071A5E1E7C0FB470E36E920",
      coinDecimals: 6
    },
    {
      coinDenom: "dydx",
      coinMinimalDenom: "ibc/2CB87BCE0937B1D1DFCEE79BE4501AAF3C265E923509AEAC410AD85D27F35130",
      coinDecimals: 18
    },
    {
      coinDenom: "newt",
      coinMinimalDenom: "factory/neutron1p8d89wvxyjcnawmgw72klknr3lg9gwwl6ypxda/newt",
      coinDecimals: 6,
      coinGeckoId: "newt"
    },
    {
      coinDenom: "astro",
      coinMinimalDenom: "factory/neutron1ffus553eet978k024lmssw0czsxwr97mggyv85lpcsdkft8v9ufsz3sa07/astro",
      coinDecimals: 6,
      coinGeckoId: "astroport-fi"
    },
    {
      coinDenom: "xASTRO",
      coinMinimalDenom: "factory/neutron1zlf3hutsa4qnmue53lz2tfxrutp8y2e3rj4nkghg3rupgl4mqy8s5jgxsn/xASTRO",
      coinDecimals: 6
    },
    {
      coinDenom: "astro.cw20",
      coinMinimalDenom: "ibc/5751B8BCDA688FD0A8EC0B292EEF1CDEAB4B766B63EC632778B196D317C40C3A",
      coinDecimals: 6
    },
    {
      coinDenom: "corgi",
      coinMinimalDenom: "factory/neutron1tklm6cvr2wxg8k65t8gh5ewslnzdfd5fsk0w3f/corgi",
      coinDecimals: 6
    },
    {
      coinDenom: "circus",
      coinMinimalDenom: "factory/neutron170v88vrtnedesyfytuku257cggxc79rd7lwt7q/ucircus",
      coinDecimals: 6
    },
    {
      coinDenom: "jimmy",
      coinMinimalDenom: "factory/neutron108x7vp9zv22d6wxrs9as8dshd3pd5vsga463yd/JIMMY",
      coinDecimals: 6
    },
    {
      coinDenom: "bad",
      coinMinimalDenom: "factory/neutron143wp6g8paqasnuuey6zyapucknwy9rhnld8hkr/bad",
      coinDecimals: 6
    },
    {
      coinDenom: "bitcosmos",
      coinMinimalDenom: "neutron1fjzg7fmv770hsvahqm0nwnu6grs3rjnd2wa6fvm9unv6vedkzekqpw44qj",
      coinDecimals: 6
    },
    {
      coinDenom: "wtf",
      coinMinimalDenom: "neutron12h09p8hq5y4xpsmcuxxzsn9juef4f6jvekp8yefc6xnlwm6uumnsdk29wf",
      coinDecimals: 6
    },
    {
      coinDenom: "nls",
      coinMinimalDenom: "ibc/6C9E6701AC217C0FC7D74B0F7A6265B9B4E3C3CDA6E80AADE5F950A8F52F9972",
      coinDecimals: 6
    },
    {
      coinDenom: "goddard",
      coinMinimalDenom: "factory/neutron1t5qrjtyryh8gzt800qr5vylhh2f8cmx4wmz9mc/ugoddard",
      coinDecimals: 6
    },
    {
      coinDenom: "apollo",
      coinMinimalDenom: "factory/neutron154gg0wtm2v4h9ur8xg32ep64e8ef0g5twlsgvfeajqwghdryvyqsqhgk8e/APOLLO",
      coinDecimals: 6
    },
    {
      coinDenom: "newtroll",
      coinMinimalDenom: "factory/neutron1ume2n42r5j0660gegrr28fzdze7aqf7r5cd9y6/newtroll",
      coinDecimals: 6
    },
    {
      coinDenom: "retro",
      coinMinimalDenom: "factory/neutron1t24nc7whl77relnu3taxyg3p66pjyuk82png2y/uretro",
      coinDecimals: 6
    },
    {
      coinDenom: "goddard",
      coinMinimalDenom: "factory/neutron1yqj9vcc0y73xfxjzegaj4v8q0zefevnlpuh4rj/GODDARD",
      coinDecimals: 6
    },
    {
      coinDenom: "WOSMO",
      coinMinimalDenom: "ibc/7DA39F5140741177846FCF3CFAB14450EE7F57B7794E5A94BEF73825D3741958",
      coinDecimals: 6
    },
    {
      coinDenom: "boy",
      coinMinimalDenom: "neutron1uqvse8fdrd9tam47f2jhy9m6al6xxtqpc83f9pdnz5gdle4swc0spfnctv",
      coinDecimals: 6
    },
    {
      coinDenom: "BADKID",
      coinMinimalDenom: "ibc/9F8417FBA11E5E01F7F85DDD48C400EB746E95084C11706041663845B4A700A8",
      coinDecimals: 6
    },
    {
      coinDenom: "cartel",
      coinMinimalDenom: "factory/neutron1w0pz4mjw7n96kkragj8etgfgakg5vw9lzg77wq/cartel",
      coinDecimals: 6
    },
    {
      coinDenom: "ATOM1KLFG",
      coinMinimalDenom: "factory/neutron13lkh47msw28yynspc5rnmty3yktk43wc3dsv0l/ATOM1KLFG",
      coinDecimals: 6
    },
    {
      coinDenom: "usdc",
      coinMinimalDenom: "ibc/B559A80D62249C8AA07A380E2A2BEA6E5CA9A6F079C912C3A9E9B494105E4F81",
      coinDecimals: 6,
      coinGeckoId: "usd-coin"
    },
    {
      coinDenom: "WEIRD",
      coinMinimalDenom: "factory/neutron133xakkrfksq39wxy575unve2nyehg5npx75nph/WEIRD",
      coinDecimals: 6
    },
    {
      coinDenom: "takumi",
      coinMinimalDenom: "factory/neutron19tynwawkm2rgefqxy7weupu4hdamyhg890zep2/TAKUMI",
      coinDecimals: 6
    },
    {
      coinDenom: "NBZ",
      coinMinimalDenom: "factory/neutron1a6ydq8urdj0gkvjw9e9e5y9r5ce2qegm9m4xufpt96kcm60kmuass0mqq4/nbz",
      coinDecimals: 6
    },
    {
      coinDenom: "MARS",
      coinMinimalDenom: "factory/neutron1ndu2wvkrxtane8se2tr48gv7nsm46y5gcqjhux/MARS",
      coinDecimals: 6,
      coinGeckoId: "mars-protocol-a7fcbcfb-fd61-4017-92f0-7ee9f9cc6da3"
    },
    {
      coinDenom: "dATOM",
      coinMinimalDenom: "factory/neutron1k6hr0f83e7un2wjf29cspk7j69jrnskk65k3ek2nj9dztrlzpj6q00rtsa/udatom",
      coinDecimals: 6,
      coinGeckoId: "drop-staked-atom"
    },
    {
      coinDenom: "SIN",
      coinMinimalDenom: "factory/neutron133xakkrfksq39wxy575unve2nyehg5npx75nph/sin",
      coinDecimals: 6
    },
    {
      coinDenom: "GOP",
      coinMinimalDenom: "factory/neutron133xakkrfksq39wxy575unve2nyehg5npx75nph/GOP",
      coinDecimals: 6
    },
    {
      coinDenom: "arena",
      coinMinimalDenom: "factory/neutron129ukd5cwahcjkccujz87rjemjukff7jf6sau72qrhva677xgz9gs4m4jeq/uarena",
      coinDecimals: 6
    },
    {
      coinDenom: "AXV",
      coinMinimalDenom: "cw20:neutron10dxyft3nv4vpxh5vrpn0xw8geej8dw3g39g7nqp8mrm307ypssksau29af",
      coinDecimals: 6,
      coinGeckoId: "astrovault"
    },
    {
      coinDenom: "axlwbtc",
      coinMinimalDenom: "ibc/DF8722298D192AAB85D86D0462E8166234A6A9A572DD4A2EA7996029DF4DB363",
      coinDecimals: 8
    },
    {
      coinDenom: "xATOM",
      coinMinimalDenom: "cw20:neutron1vjl4ze7gr32lar5s4fj776v70j4ml7mlt4aqln2hwgfhqjck8xwqfhx8vj",
      coinDecimals: 6,
      coinGeckoId: "astrovault-xatom"
    },
    {
      coinDenom: "amATOM",
      coinMinimalDenom: "factory/neutron1shwxlkpdjd8h5wdtrykypwd2v62z5glr95yp0etdcspkkjwm5meq82ndxs/amatom",
      coinDecimals: 6
    },
    {
      coinDenom: "JSD",
      coinMinimalDenom: "factory/neutron1mdy5fhtwdjagp5eallsdhlx6gxylm8rxqk72wjzg6y5d5kt44ysqprkduw/JSD",
      coinDecimals: 6
    },
    {
      coinDenom: "FUEL",
      coinMinimalDenom: "factory/neutron1zl2htquajn50vxu5ltz0y5hf2qzvkgnjaaza2rssef268xplq6vsjuruxm/fuel",
      coinDecimals: 6
    },
    {
      coinDenom: "bglUSDC",
      coinMinimalDenom: "factory/neutron16ue9kysgneyqktmjxdfshajgvlrcx9rehxz8x9th7g8fgtnlxwuqvg9mgp/bglUSDC",
      coinDecimals: 6
    },
    {
      coinDenom: "dTIA",
      coinMinimalDenom: "factory/neutron1ut4c6pv4u6vyu97yw48y8g7mle0cat54848v6m97k977022lzxtsaqsgmq/udtia",
      coinDecimals: 6
    }
  ],
  rest: "https://api.novel.remedy.tm.p2p.org",
  rpc: "https://rpc-voidara.neutron-1.neutron.org",
  bech32Config: {
    bech32PrefixAccAddr: "neutron",
    bech32PrefixAccPub: "neutronpub",
    bech32PrefixValAddr: "neutronvaloper",
    bech32PrefixValPub: "neutronvaloperpub",
    bech32PrefixConsAddr: "neutronvalcons",
    bech32PrefixConsPub: "neutronvalconspub"
  },
  chainName: "neutron",
  feeCurrencies: [
    {
      coinDenom: "ntrn",
      coinMinimalDenom: "untrn",
      coinDecimals: 6,
      coinGeckoId: "neutron-3",
      gasPriceStep: {
        low: 0.0053,
        average: 0.0053,
        high: 0.0053
      }
    },
    {
      coinDenom: "atom",
      coinMinimalDenom: "ibc/C4CFF46FD6DE35CA4CF4CE031E643C8FDC9BA4B99AE598E9B0ED98FE3A2319F9",
      coinDecimals: 6,
      coinGeckoId: "",
      gasPriceStep: {
        low: 0.0008,
        average: 0.0008,
        high: 0.0008
      }
    },
    {
      coinDenom: "axlusdc",
      coinMinimalDenom: "ibc/F082B65C88E4B6D5EF1DB243CDA1D331D002759E938A0F5CD3FFDC5D53B3E349",
      coinDecimals: 6,
      coinGeckoId: "",
      gasPriceStep: {
        low: 0.008,
        average: 0.008,
        high: 0.008
      }
    },
    {
      coinDenom: "wstETH",
      coinMinimalDenom: "factory/neutron1ug740qrkquxzrk2hh29qrlx3sktkfml3je7juusc2te7xmvsscns0n2wry/wstETH",
      coinDecimals: 18,
      coinGeckoId: "",
      gasPriceStep: {
        low: 2903231.6597,
        average: 2903231.6597,
        high: 2903231.6597
      }
    },
    {
      coinDenom: "dydx",
      coinMinimalDenom: "ibc/2CB87BCE0937B1D1DFCEE79BE4501AAF3C265E923509AEAC410AD85D27F35130",
      coinDecimals: 18,
      coinGeckoId: "",
      gasPriceStep: {
        low: 2564102564.1026,
        average: 2564102564.1026,
        high: 2564102564.1026
      }
    },
    {
      coinDenom: "tia",
      coinMinimalDenom: "ibc/773B4D0A3CD667B2275D5A4A7A2F0909C0BA0F4059C0B9181E680DDF4965DCC7",
      coinDecimals: 6,
      coinGeckoId: "",
      gasPriceStep: {
        low: 0.0004,
        average: 0.0004,
        high: 0.0004
      }
    }
  ],
  stakeCurrency: {
    coinDenom: "ntrn",
    coinMinimalDenom: "untrn",
    coinDecimals: 6,
    coinGeckoId: "neutron-3"
  },
  bip44: {
    coinType: 118
  }
});

export const terra2 = defineChainInfo({
  chainId: "phoenix-1",
  currencies: [
    {
      coinDenom: "luna",
      coinMinimalDenom: "uluna",
      coinDecimals: 6,
      coinGeckoId: "terra-luna-2"
    },
    {
      coinDenom: "astro.cw20",
      coinMinimalDenom: "cw20:terra1nsuqsk6kh58ulczatwev87ttq2z6r3pusulg9r24mfj2fvtzd4uq3exn26",
      coinDecimals: 6
    },
    {
      coinDenom: "astro",
      coinMinimalDenom: "ibc/8D8A7F7253615E5F76CB6252A1E1BD921D5EDB7BBAAF8913FB1C77FF125D9995",
      coinDecimals: 6
    },
    {
      coinDenom: "cw20:terra1spkm49wd9dqkranhrks4cupecl3rtgeqqljq3qrvrrts2ev2gw6sy5vz3k",
      coinMinimalDenom: "cw20:terra1spkm49wd9dqkranhrks4cupecl3rtgeqqljq3qrvrrts2ev2gw6sy5vz3k",
      coinDecimals: 0
    },
    {
      coinDenom: "Reis",
      coinMinimalDenom: "cw20:terra1sdglum2dt4f3fmq7jrt2phf2tegmnudc7qqqqujkpqcm9ujuxxkqakv5u8",
      coinDecimals: 6
    },
    {
      coinDenom: "Escudos",
      coinMinimalDenom: "cw20:terra1qj5hs3e86qn4vm9dvtgtlkdp550r0rayk9wpay44mfw3gn3tr8nq5jw3dg",
      coinDecimals: 6
    },
    {
      coinDenom: "Alem",
      coinMinimalDenom: "cw20:terra1cmf8ytutcwrjrv08zskj9phuh46a3w3nkjax7en4hxezsrdr58lqvzy05q",
      coinDecimals: 6
    },
    {
      coinDenom: "AMG",
      coinMinimalDenom: "cw20:terra1en42e2vsvtdsvrcqg5s5e5e4djejjaed6fxmvtpweg3tu33h6k5qkg9c40",
      coinDecimals: 6
    },
    {
      coinDenom: "ARK",
      coinMinimalDenom: "cw20:terra1ysd87nayjuelxj4wvp4wnp9as0mwszzkje6a9z6f3xx2903ghnsq4hm50y",
      coinDecimals: 6
    },
    {
      coinDenom: "DANU",
      coinMinimalDenom: "cw20:terra1vj68f2ntauaj5dfvy5z4tq4we3hsyg363k63js5sae2t7th9aacqan89sr",
      coinDecimals: 6
    },
    {
      coinDenom: "TRS",
      coinMinimalDenom: "cw20:terra1swzpenwh39f8aa7qht34r05f8ew6k2vehvqt2aw4fjy0fgghhheqs9l6h7",
      coinDecimals: 6
    },
    {
      coinDenom: "PLASMA",
      coinMinimalDenom: "cw20:terra1mqs6mdx0ak7qvjzs6efhg65g4j5pzwpdkcgdsv5tpekln3qhggrq3qzvdp",
      coinDecimals: 6
    },
    {
      coinDenom: "DMT",
      coinMinimalDenom: "cw20:terra1xfkkgwxychgrnq0vcp82u32mecrzj8s64s03cxtmf46k6j2n2wssc7rzjf",
      coinDecimals: 6
    },
    {
      coinDenom: "ampluna",
      coinMinimalDenom: "cw20:terra1ecgazyd0waaj3g7l9cmy5gulhxkps2gmxu9ghducvuypjq68mq2s5lvsct",
      coinDecimals: 6,
      coinGeckoId: "eris-amplified-luna"
    },
    {
      coinDenom: "roar",
      coinMinimalDenom: "cw20:terra1lxx40s29qvkrcj8fsa3yzyehy7w50umdvvnls2r830rys6lu2zns63eelv",
      coinDecimals: 6,
      coinGeckoId: "lion-dao"
    },
    {
      coinDenom: "gem",
      coinMinimalDenom: "cw20:terra1ynvsz80w9xmhdxucv96gkwpxlwvjgsq75xh2f3pf825c4wfmkfxskq6pqv",
      coinDecimals: 6
    },
    {
      coinDenom: "cub",
      coinMinimalDenom: "cw20:terra1lalvk0r6nhruel7fvzdppk3tup3mh5j4d4eadrqzfhle4zrf52as58hh9t",
      coinDecimals: 6
    },
    {
      coinDenom: "blue",
      coinMinimalDenom: "cw20:terra1gwrz9xzhqsygyr5asrgyq3pu0ewpn00mv2zenu86yvx2nlwpe8lqppv584",
      coinDecimals: 6
    },
    {
      coinDenom: "xxx",
      coinMinimalDenom: "cw20:terra10se906awphtccf4vd83m0ulpmpt9v4msuakmpy0pwvmtxmup3kdq25rayn",
      coinDecimals: 10
    },
    {
      coinDenom: "GUGU",
      coinMinimalDenom: "cw20:terra19nu4q2y0xe3vza6ve5ymup33uzm049pnrqq5ddeuulmrg279t57qstcces",
      coinDecimals: 6
    },
    {
      coinDenom: "bLUNA",
      coinMinimalDenom: "cw20:terra17aj4ty4sz4yhgm08na8drc0v03v2jwr3waxcqrwhajj729zhl7zqnpc0ml",
      coinDecimals: 6,
      coinGeckoId: "backbone-labs-staked-luna"
    },
    {
      coinDenom: "sayve",
      coinMinimalDenom: "cw20:terra1xp9hrhthzddnl7j5du83gqqr4wmdjm5t0guzg9jp6jwrtpukwfjsjgy4f3",
      coinDecimals: 6
    },
    {
      coinDenom: "ampWHALEt",
      coinMinimalDenom: "factory/terra1j35ta0llaxcf55auv2cjqau5a7aee6g8fz7md7my7005cvh23jfsaw83dy/ampWHALEt",
      coinDecimals: 6
    },
    {
      coinDenom: "boneWHALEt",
      coinMinimalDenom: "factory/terra10j3zrymfrkta2pxe0gklc79gu06tqyuy8c3kh6tqdsrrprsjqkrqzfl4df/boneWHALEt",
      coinDecimals: 6
    },
    {
      coinDenom: "ampROAR",
      coinMinimalDenom: "factory/terra1vklefn7n6cchn0u962w3gaszr4vf52wjvd4y95t2sydwpmpdtszsqvk9wy/ampROAR",
      coinDecimals: 6
    },
    {
      coinDenom: "nico",
      coinMinimalDenom: "cw20:terra1e0efrrrj8d55pflme3dmtyuj7klzcef5cfmz6r2jyqz77kk2jz3qa6drg3",
      coinDecimals: 18
    },
    {
      coinDenom: "seas",
      coinMinimalDenom: "cw20:terra1rc6ssp5rym7a0hg29xpj4cc9e67tl56kg5jyzgl9qrhfxxc2ugvsnrkala",
      coinDecimals: 6
    },
    {
      coinDenom: "bitz",
      coinMinimalDenom: "cw20:terra18vp5s0r7keezm35hdxsgw8zgfnyn8wejdkk893ag2kqncgpqxhjqwjpc0v",
      coinDecimals: 6
    },
    {
      coinDenom: "factory/neutron1ug740qrkquxzrk2hh29qrlx3sktkfml3je7juusc2te7xmvsscns0n2wry/wstETH",
      coinMinimalDenom: "ibc/A356EC90DC3AE43D485514DA7260EDC7ABB5CFAA0654CE2524C739392975AD3C",
      coinDecimals: 18
    },
    {
      coinDenom: "seul",
      coinMinimalDenom: "cw20:terra13s5pxw5j2p4ssvzwvxd8l7h30vke8vjgtng75vqgv6p9vddfk3hskfka0l",
      coinDecimals: 6
    },
    {
      coinDenom: "xseul",
      coinMinimalDenom: "cw20:terra1q328gl40az3cf9x67cgudn8e8w2az9vsmhtkwsgdu7a43rhy5caqc82yr5",
      coinDecimals: 6
    },
    {
      coinDenom: "ito",
      coinMinimalDenom: "cw20:terra1c77xqv746m7ghxayrge79dxr4kcezev8g6cnrfled4f3n4ufj0vs5gz28s",
      coinDecimals: 6
    },
    {
      coinDenom: "ARMANI",
      coinMinimalDenom: "cw20:terra1aa7stl3fytvave9xtcexgv0kne4k7ks068dcljkrfj37hy8q270sjadav8",
      coinDecimals: 6
    },
    {
      coinDenom: "DROGO",
      coinMinimalDenom: "cw20:terra1cl273523kmr2uwjhhznq54je69mted2u3ljffm8kp2ap4z3drdksftwqun",
      coinDecimals: 6
    },
    {
      coinDenom: "ADO",
      coinMinimalDenom: "cw20:terra1w8xk6rtu40st6lvl3yv7ynw5urm2n686u9cchvrzltmnktzwdesqcwy0nu",
      coinDecimals: 6
    },
    {
      coinDenom: "WOSMO",
      coinMinimalDenom: "ibc/E18C0D303957867A164DE2863D1C3F83135936E6F17CADF4D241FBC12B0F23B2",
      coinDecimals: 6
    },
    {
      coinDenom: "DNA",
      coinMinimalDenom: "cw20:terra1epka8wevu90rxlvt6g5x9veevd800a760vtuftc5kqdfmrh47qds9f0m8h",
      coinDecimals: 6
    },
    {
      coinDenom: "bitmos",
      coinMinimalDenom: "cw20:terra1sxe8u2hjczlekwfkcq0rs28egt38pg3wqzfx4zcrese4fnvzzupsk9gjkq",
      coinDecimals: 6
    },
    {
      coinDenom: "lads",
      coinMinimalDenom: "cw20:terra1eh8eq60cjy997w5dc3a6exfzanlaurupav8klx7m9u9ddfgh25mqjwl5vj",
      coinDecimals: 6
    },
    {
      coinDenom: "rstk",
      coinMinimalDenom: "ibc/F709DF4969CD26174C1A53AA95F3D98BE643C1A52C9981487766F96A1811F6A4",
      coinDecimals: 6
    },
    {
      coinDenom: "CLON",
      coinMinimalDenom: "cw20:terra164ssz60yvsxey0ku9mtcaegdeyxwzuwwqyrp238nvflwqve0pvxsra7fa2",
      coinDecimals: 6
    },
    {
      coinDenom: "MOAR",
      coinMinimalDenom: "factory/terra1dndhtdr2v7ca8rrn67chlqw3cl3xhm3m2uxls62vghcg3fsh5tpss5xmcu/MOAR",
      coinDecimals: 6
    },
    {
      coinDenom: "usdt",
      coinMinimalDenom: "ibc/9B19062D46CAB50361CE9B0A3E6D0A7A53AC9E7CB361F32A73CC733144A9A9E5",
      coinDecimals: 6
    },
    {
      coinDenom: "atom-luna-lp",
      coinMinimalDenom: "factory/terra1djk2zl83dspt696ex5crhfacu8vm6934576t4zdd2592fzyahr2qma6guq/ULUN-IBC/-LP",
      coinDecimals: 6
    },
    {
      coinDenom: "luna-usdc-lp",
      coinMinimalDenom: "factory/terra12jxfw2vg4cu6mxlgf39dp5ccxtuwm468w8eh9cnh2qsxc9t0sffs7ekhft/ULUN-IBC/-LP",
      coinDecimals: 6
    },
    {
      coinDenom: "luna-usdt-lp",
      coinMinimalDenom: "factory/terra1w9spejtuac5dt0gympq576uhwde39exhh7hdxwl99rjvaphfukkq6y4cv7/ULUN-IBC/-LP",
      coinDecimals: 6
    },
    {
      coinDenom: "ashLUNA",
      coinMinimalDenom: "factory/terra1f5cfm48gcezl3cx25l64ngc4yktnmx7rcpj3kggu6v273742sqqs5yn5ks/luna.ash",
      coinDecimals: 6
    },
    {
      coinDenom: "usdc",
      coinMinimalDenom: "ibc/2C962DAB9F57FE0921435426AE75196009FAA1981BF86991203C8411F8980FDB",
      coinDecimals: 6
    },
    {
      coinDenom: "COSMO",
      coinMinimalDenom: "ibc/4925733868E7999F5822C961ADE9470A7FC5FA4A560BAE1DE102783C3F64C201",
      coinDecimals: 6
    },
    {
      coinDenom: "DGN",
      coinMinimalDenom: "ibc/B2AA4C3CD19954859C3B537EC0705640AFC01075F52993D9AC5E73F07F0386CC",
      coinDecimals: 6
    },
    {
      coinDenom: "whale",
      coinMinimalDenom: "ibc/36A02FFC4E74DF4F64305130C3DFA1B06BEAC775648927AA44467C76A77AB8DB",
      coinDecimals: 6
    }
  ],
  rest: "https://lcd-terra.wildsage.io",
  rpc: "https://rpc.lavenderfive.com:443/terra2",
  bech32Config: {
    bech32PrefixAccAddr: "terra",
    bech32PrefixAccPub: "terrapub",
    bech32PrefixValAddr: "terravaloper",
    bech32PrefixValPub: "terravaloperpub",
    bech32PrefixConsAddr: "terravalcons",
    bech32PrefixConsPub: "terravalconspub"
  },
  chainName: "terra2",
  feeCurrencies: [
    {
      coinDenom: "luna",
      coinMinimalDenom: "uluna",
      coinDecimals: 6,
      coinGeckoId: "terra-luna-2",
      gasPriceStep: {
        low: 0.015,
        average: 0.015,
        high: 0.04
      }
    }
  ],
  stakeCurrency: {
    coinDenom: "luna",
    coinMinimalDenom: "uluna",
    coinDecimals: 6,
    coinGeckoId: "terra-luna-2"
  },
  bip44: {
    coinType: 330
  }
});

export const cosmoshubtestnet = defineChainInfo({
  chainId: "theta-testnet-001",
  currencies: [
    {
      coinDenom: "atom",
      coinMinimalDenom: "uatom",
      coinDecimals: 6
    }
  ],
  rest: "https://cosmoshubt.lava.build",
  rpc: "https://cosmoshubt.tendermintrpc.lava.build:443",
  bech32Config: {
    bech32PrefixAccAddr: "cosmos",
    bech32PrefixAccPub: "cosmospub",
    bech32PrefixValAddr: "cosmosvaloper",
    bech32PrefixValPub: "cosmosvaloperpub",
    bech32PrefixConsAddr: "cosmosvalcons",
    bech32PrefixConsPub: "cosmosvalconspub"
  },
  chainName: "cosmoshubtestnet",
  feeCurrencies: [
    {
      coinDenom: "atom",
      coinMinimalDenom: "uatom",
      coinDecimals: 6,
      coinGeckoId: "",
      gasPriceStep: {
        low: 0.01,
        average: 0.025,
        high: 0.03
      }
    }
  ],
  stakeCurrency: {
    coinDenom: "atom",
    coinMinimalDenom: "uatom",
    coinDecimals: 6
  },
  bip44: {
    coinType: 118
  }
});

export const neutrontestnet = defineChainInfo({
  chainId: "pion-1",
  currencies: [
    {
      coinDenom: "ntrn",
      coinMinimalDenom: "untrn",
      coinDecimals: 6
    },
    {
      coinDenom: "amATOM",
      coinMinimalDenom: "factory/neutron15lku24mqhvy4v4gryrqs4662n9v9q4ux9tayn89cmdzldjcgawushxvm76/amatom",
      coinDecimals: 6
    },
    {
      coinDenom: "arena",
      coinMinimalDenom: "factory/neutron1r3slyjlf7g76mz3na6gh7c8ek62rhssrzf60uh0emyw3x94rppyqfcs0pk/uarena",
      coinDecimals: 6
    }
  ],
  rest: "https://rest-falcron.pion-1.ntrn.tech",
  rpc: "https://rpc-falcron.pion-1.ntrn.tech",
  bech32Config: {
    bech32PrefixAccAddr: "neutron",
    bech32PrefixAccPub: "neutronpub",
    bech32PrefixValAddr: "neutronvaloper",
    bech32PrefixValPub: "neutronvaloperpub",
    bech32PrefixConsAddr: "neutronvalcons",
    bech32PrefixConsPub: "neutronvalconspub"
  },
  chainName: "neutrontestnet",
  feeCurrencies: [
    {
      coinDenom: "ntrn",
      coinMinimalDenom: "untrn",
      coinDecimals: 6,
      coinGeckoId: "",
      gasPriceStep: {
        low: 0.0053,
        average: 0.0053,
        high: 0.0053
      }
    }
  ],
  stakeCurrency: {
    coinDenom: "ntrn",
    coinMinimalDenom: "untrn",
    coinDecimals: 6
  },
  bip44: {
    coinType: 118
  }
});

export const cosmosicsprovidertestnet = defineChainInfo({
  chainId: "provider",
  currencies: [
    {
      coinDenom: "atom",
      coinMinimalDenom: "uatom",
      coinDecimals: 6
    }
  ],
  rest: "https://rest.provider-sentry-01.ics-testnet.polypore.xyz",
  rpc: "https://rpc.provider-sentry-01.ics-testnet.polypore.xyz",
  bech32Config: {
    bech32PrefixAccAddr: "cosmos",
    bech32PrefixAccPub: "cosmospub",
    bech32PrefixValAddr: "cosmosvaloper",
    bech32PrefixValPub: "cosmosvaloperpub",
    bech32PrefixConsAddr: "cosmosvalcons",
    bech32PrefixConsPub: "cosmosvalconspub"
  },
  chainName: "cosmosicsprovidertestnet",
  feeCurrencies: [
    {
      coinDenom: "atom",
      coinMinimalDenom: "uatom",
      coinDecimals: 6,
      coinGeckoId: "",
      gasPriceStep: {
        low: 0.01,
        average: 0.025,
        high: 0.03
      }
    }
  ],
  stakeCurrency: {
    coinDenom: "atom",
    coinMinimalDenom: "uatom",
    coinDecimals: 6
  },
  bip44: {
    coinType: 118
  }
});