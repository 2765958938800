const Level = {
    INFO:"info",
    WARN:"warn",
    ERROR:"error",
    NONE:"none"
}

export function log(s, level = Level.NONE){
    if (level == Level.INFO) {
        console.log(s);
    }
}