import React, { createContext } from 'react'
import './style.scss'
import { CookiesProvider, useCookies } from 'react-cookie'
import { GrazProvider } from "graz";
import { cosmoshub, cosmoshubtestnet, cosmosicsprovidertestnet, neutron, neutrontestnet, terra2 } from "./chains.js";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import ConnWrapper from './Components/ConnWrapper.js';

const queryClient = new QueryClient();

function App() {
    const WC_PROJECT_ID = "48a949832f6826ffa556e316e4b5261b";

    const [cookies, setCookie] = useCookies(['user'])

    function handleAgreement(new_map) {
        let map = { ...new_map };
        setCookie('isAgreement', map, { path: '/' })
    }

    return (
        <QueryClientProvider client={queryClient} >
            <GrazProvider
                grazOptions={{
                    chains: [cosmoshub, neutron, terra2, neutrontestnet, cosmosicsprovidertestnet],
                    walletConnect: {
                        options: {
                            projectId: WC_PROJECT_ID,
                        },
                    },
                }}
            >
                <CookiesProvider>
                    <ConnWrapper isAgreement={cookies.isAgreement || { '1': false, '2': false }} handleAgreement={handleAgreement} />
                </CookiesProvider>
            </GrazProvider>
        </QueryClientProvider>
    )
}

export default App