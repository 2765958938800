import { useContext } from "react";
import { ConnContext } from "./ConnWrapper";
import { getConfig, getDefaultChain } from "../lib/config.js";


export function useWallet(){
  const {account, chainFrom, setChainFrom} = useContext(ConnContext);
  return parseWallet(account, chainFrom);
}

export function parseWallet(account, chain_id) {
    let wallet = {};
    if (!account) return wallet;
    if (!chain_id) return wallet;
    if (!account[chain_id].bech32Address) return wallet;
    wallet.address = account[chain_id].bech32Address;
    wallet.chainId = chain_id
    wallet.network = getConfig().active_lcd;
    wallet.defaultChainAddress = account[getDefaultChain().chain_id].bech32Address;
    return wallet;
}