import React, { createContext } from 'react'
import '../style.scss'
import Main from '../Components/Main'
import { useAccount } from "graz";
import { getDefaultChain, getEnabledChains } from '../lib/config.js';
import { log } from '../lib/log.js';

export const ConnContext = createContext(null);

function ConnWrapper(props) {

  const { data: account, isConnecting, isConnected } = useAccount({
    chainId: Object.values(getEnabledChains()).map((c) => c.chain_id), //["neutron-1", "cosmoshub-4"],
    multiChain: true,
    onConnect: () => {
      log(account, "info");
    }
  });
  const [chainFrom, setChainFrom] = React.useState(getDefaultChain().chain_id);

  return (

    <ConnContext.Provider value={{ account: account, chainFrom: chainFrom, setChainFrom: setChainFrom }}>
      <Main isAgreement={props.isAgreement || { '1': false, '2': false }} handleAgreement={props.handleAgreement} />
    </ConnContext.Provider>
  )
}

export default ConnWrapper