export const config = {
    "contract_addr": {
        "mainnet": "neutron156u7mg0dqq8ssm5x0332ucep3zkg3d6dnxalyaw6429vsw3kyq3q8snpyk",
        "testnet": "neutron15pru2xka2ry5j29c99aup8ndwz7hx7ww2s7rseu89uq9q4rz03pq59grwu"
    },
    "backend_addr": {
        "mainnet": "neutron1v59je6ns5u5vt86aaapdc3u72q25a3wkqka3tx",
        "testnet": "neutron1v59je6ns5u5vt86aaapdc3u72q25a3wkqka3tx"
    },
    "active_lcd": "testnet",
    "lcd": {
        "mainnet": {
            "neutron-1": {
                "chainID": "neutron-1",
                "lcd": "https://neutron-api.polkachu.com:443",
                "gasAdjustment": 1.75,
                "gasPrices": {
                    "uluna": 0.015
                },
                "prefix": "neutron"
            }
        },
        "testnet": {
            "pion-1": {
                "chainID": "pion-1",
                "lcd": "https://rest-falcron.pion-1.ntrn.tech:443",
                "gasAdjustment": 1.75,
                "gasPrices": {
                    "uluna": 0.015
                },
                "prefix": "neutron"
            }
        }
    },

    "enabled_tokens": [
        { "name": "atom", "display": "ATOM", "icon": "assets/images/atom.svg", "chain_icon": "assets/images/atom.svg", "type": "ibc", "chain_id": "provider", "chain_name": "CosmosHub", "native_denom": "uatom", "whitelisting_address": "ibc/D1283F23CC25C39F16BCFB2DFFA9997AE7A101B92810D0F9F0AA092F6FE332D0", "address": "uatom", "fees": "200000", "default_token": 1 },
        { "name": "ntrn", "display": "NTRN", "icon": "assets/images/ntrn.svg", "chain_icon": "assets/images/ntrn.svg", "type": "native", "chain_id": "pion-1", "chain_name": "Neutron", "native_denom": "untrn", "whitelisting_address": "untrn", "address": "untrn", "fees": "50000", "default_chain": 1, "default_token": 1 },
        { "name": "atom", "display": "ATOM", "icon": "assets/images/atom.svg", "chain_icon": "assets/images/atom.svg", "type": "ibc", "chain_id": "pion-1", "chain_name": "Neutron", "native_denom": "uatom", "whitelisting_address": "ibc/C4CFF46FD6DE35CA4CF4CE031E643C8FDC9BA4B99AE598E9B0ED98FE3A2319F9", "address": "ibc/C4CFF46FD6DE35CA4CF4CE031E643C8FDC9BA4B99AE598E9B0ED98FE3A2319F9", "fees": "200000" },
        { "name": "astro", "display": "ASTRO", "icon": "assets/images/astro.svg", "chain_icon": "assets/images/ntrn.svg", "type": "native", "chain_id": "pion-1", "chain_name": "Neutron", "native_denom": "factory/neutron1ffus553eet978k024lmssw0czsxwr97mggyv85lpcsdkft8v9ufsz3sa07/astro", "whitelisting_address": "factory/neutron1ffus553eet978k024lmssw0czsxwr97mggyv85lpcsdkft8v9ufsz3sa07/astro", "address": "factory/neutron1ffus553eet978k024lmssw0czsxwr97mggyv85lpcsdkft8v9ufsz3sa07/astro", "fees": "50000" }
    ],
    "cosmoburn_tokens": [
        { "name": "anew", "display": "ANEW", "icon": "assets/images/anew.svg", "type": "native", "chain_id": "pion-1", "native_denom": "factory/neutron1qfmcff2w253qha7jf53p4ttqyfccce6k539zgg/anew", "address": "factory/neutron1qfmcff2w253qha7jf53p4ttqyfccce6k539zgg/anew" },
        { "name": "acanew", "display": "acANEW", "icon": "assets/images/acanew.svg", "type": "native", "chain_id": "pion-1", "native_denom": "factory/neutron1qfmcff2w253qha7jf53p4ttqyfccce6k539zgg/acanew", "address": "factory/neutron1qfmcff2w253qha7jf53p4ttqyfccce6k539zgg/acanew" }
    ]
}