
import React, { useCallback, useState, useEffect } from 'react';
import { Buffer } from 'buffer'
import { getChainId, getContractAddress, parseWallet, getEnabledTokens } from '../lib/config';

import axios from 'axios';

import { useActiveChainIds, useCosmWasmSigningClient, useExecuteContract, useSendIbcTokens, useStargateSigningClient } from 'graz';
import { GasPrice } from '@cosmjs/stargate';
import { mapError } from '../lib/errors';
import { log } from '../lib/log';

function Ibc(props) {

    const [txResult, setTxResult] = useState(null);

    const connectedWallet = props.account;

    log("SIGNING CLIENT");
    log(connectedWallet);

    const { data: signingClient } = useStargateSigningClient({
        opts: {
            gasPrice: GasPrice.fromString("0.005uatom"),
//            gasPrice: GasPrice.fromString("0.012untrn"),
        }
    })

    const activeChainIds = useActiveChainIds();
    log(activeChainIds);

    const { sendIbcTokens } = useSendIbcTokens({
        onError: (e) => { log("IBCRESe");log(e); },
        onSuccess: (r) => { log("IBCRESs");log(r) },
        onLoading: (a) => { log("IBCRESl");log(a) },
        }
    );

    useEffect(() => {
        if (!connectedWallet) {
            return;
        }

        if (txResult) {
            return;
        }

        if (!signingClient){
            return;
        }
        log("send IBC");
        let result = sendIbcTokens({
            signingClient: signingClient,
            senderAddress: "cosmos1ptetxhafn2506qxlljgxjd7ruy4fuafecaukrf",
            recipientAddress: "neutron1tglf0p7zkhqq5ngsrrlyq4t55ntcc9suu4czqdmpqpxvmmylm5kqscuxxs",
            transferAmount: { amount: "345", denom: "uatom" },
            sourcePort: "transfer",
            sourceChannel: "channel-569",
            timeoutHeight: {
                revisionHeight: 33843602,
                revisionNumber: 1
            },
            timeoutTimestamp: 2736163341997943817,
            fee: "auto",
            memo: `{"wasm": { "contract": "neutron1tglf0p7zkhqq5ngsrrlyq4t55ntcc9suu4czqdmpqpxvmmylm5kqscuxxs", "msg": {"add": {}}}}`
        })
        log(result);

    }, [signingClient]);

    return (
        <>
            <div>IB SIIIIIII</div>
        </>
    );
}
export default Ibc;
